.nav_items:hover {
    transform: scale(2)
}
html {
    scroll-behavior: smooth;
  }
  .MuiCard-root{
    display: flex;
  flex-direction: column;
  justify-content: space-between;
}
/* .nav_items {
    background-color: gold;
} */